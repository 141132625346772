import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { functions } from 'src/producers/firebase'
import { ContractStatus } from 'src/pages/ContractV2/contract.types'

export const fetchEnvelope: producer = async ({
  contractId = observe.contract.originalv2.id,
  trigger = observe.contract.triggers.refreshEnvelope,

  envelopeUid = get.contract.originalv2.envelopeInfo.envelopeUid,
  // user = get.user.value(),
  updateEnvelope = update.contract.originalv2.envelope,
  updateStatus = update.contract.originalv2.status,
  error = update.errors[param.id],
  updateTrigger = update.contract.triggers.refreshEnvelope
}) => {
  envelopeUid = envelopeUid.value()
  console.log(envelopeUid, contractId, trigger, !envelopeUid || (!contractId && !trigger))
  if (!envelopeUid || (!contractId && !trigger)) return
  // updateTrigger.remove()

  try {
    const payload = {
      contractId,
      envelopeUid: envelopeUid
    }
    const getEnvelopeStatus = functions.httpsCallable('getEnvelopeStatus')
    const envelope = await getEnvelopeStatus(payload)
    console.log(envelope.data)
    const sealEnvelope = functions.httpsCallable('sealEnvelope')
    await sealEnvelope(payload)
    updateEnvelope.set(envelope.data)
    // if (envelope.completed && (!envelope.trashed || !envelope.aborted))
    //   updateStatus.set(ContractStatus.ACTIVE)
  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'envelope' }
    )
  }
}
